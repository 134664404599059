import React, { useCallback, useState } from 'react';
import { TextField, Button, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import { login } from 'actions/auth';
import routes from 'config/routes';
import { useI18n } from 'locales';
import { useDispatch } from 'utils/redux';

import ErrorMessage from '../ErrorMessage';
import styles from './Login.module.scss';
import { VerificationCodeModal } from 'components/accounts/modules/DoubleAuthModal';

const Login = () => {
  const {
    auth: { login: lexique },
  } = useI18n();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    email: '',
    password: '',
    loading: false,
    errorCode: '',
  });

  const [otpState, setOtpState] = useState({
    open: false,
    otpId: "",
  })

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
      errorCode: '',
    }));
  }, []);

  const handleLogin = useCallback(
    async (e) => {
      e.preventDefault();
      setState((prevState) => ({
        ...prevState,
        loading: true,
        errorCode: '',
      }));
      const { error, otp_user_id } = await dispatch(
        login({
          email: state.email,
          password: state.password,
        })
      );
      setState((prevState) => ({
        ...prevState,
        loading: false,
        errorCode: error || '',
      }));

      if (otp_user_id) {
        setOtpState({
          open: true,
          otpId: otp_user_id,
        });
      }
    },
    [dispatch, state.email, state.password, otpState]
  );

  return (
    <div className={styles.backgound}>
      <form className={styles.container} onSubmit={handleLogin}>
        <h1 className={styles.title}>{lexique.login}</h1>
        <div className={styles.fields}>
          <TextField
            id="auth-login-email"
            label={lexique.email}
            variant="outlined"
            type="email"
            name="email"
            value={state.email}
            onChange={handleChange}
            disabled={state.loading}
            error={!!state.errorCode}
          />
          <TextField
            id="auth-login-password"
            label={lexique.password}
            variant="outlined"
            type="password"
            name="password"
            value={state.password}
            onChange={handleChange}
            disabled={state.loading}
            error={!!state.errorCode}
          />
          {state.errorCode && (
            <ErrorMessage context="login" errorCode={state.errorCode} />
          )}
          <VerificationCodeModal
            open={otpState.open}
            otp_user_id={otpState.otpId}
            onClose={() => setOtpState({
              open: false,
              otpId: ""
            })}
            email={state.email}
            password={state.password}
          />
        </div>
        <Button
          size="large"
          type="submit"
          disabled={state.loading || !state.email || !state.password}
          variant="contained"
          className="btn-raised btn-raised-green"
        >
          {state.loading ? lexique.loading : lexique.login}
        </Button>
        <div className={styles.askIfPasswordForgotten}>
          <Link
            to={routes.auth.requestPasswordReset}
            color="inherit"
            component={RouterLink}
          >
            {lexique.askIfPasswordForgotten}
          </Link>
        </div>
      </form>
    </div>
  );
};

Login.displayName = 'Login';

export default Login;
