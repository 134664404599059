import { get, post, put } from './index';
import { env, ENV_DEV, DEV_DEBUG } from 'config/env';
import * as mapper from 'mappers/accounts';

import {
  generateSearchAccount,
  generateRandomUser,
  generateRandomCompany,
  generateRandomCompanyLicences,
} from "utils/random";

const DEBUG = (env === ENV_DEV) && DEV_DEBUG;

export const doSearch = query => {

	const endpoint = `catalog/search/objects`;
	const mapped = mapper.doSearch.toApi(query);
	if (!DEBUG) return get(endpoint, mapped);

	console.info("api.accounts.doSearch - ", endpoint, " - in", query, "mapped", mapped); //eslint-disable-line no-console
	return new Promise(resolve => {
		setTimeout(() => {
			const accounts = Array(query.limit || 50).fill().map(generateSearchAccount)
			resolve(accounts)
		}, 500);
	});
}


export const loadAccount = ({id, isAdmin}) => {
	const endpoint = isAdmin ? `catalog/admin_users/${id}` : `catalog/contacts/${id}`;
	if (!DEBUG) return get(endpoint);

	console.info("api.accounts.loadAccount - ", endpoint); //eslint-disable-line no-console
	return new Promise(resolve => {
		setTimeout(() => {
			resolve(generateRandomUser())
		}, 500);
	});
}

export const saveAccount = ({isNew, ...account}) => {

	const startEndpoint = account.isAdmin ? 'catalog/admin_users' : 'catalog/contacts';
	const endpoint = isNew ? startEndpoint : `${startEndpoint}/${account.id}`;

	const params = mapper.saveAccount.toApi(account);
	if (!DEBUG) {
		return isNew ? post(endpoint, params, [422]) : put(endpoint, params, null, {'204':{}});
	}

	console.info("api.accounts.saveAccount - ", endpoint, account, params); //eslint-disable-line no-console
	return new Promise(resolve => {
		setTimeout(() => {
			resolve({
				id: "michel"
			})
		}, 500);
	});
}

export const activateContactAccount = account => {
	const endpoint = `catalog/${account.isAdmin ? 'admin_users' : 'contacts'}/${account.id}/activate`;
	if (!DEBUG) {
		return put(endpoint);
	}

	console.info("api.accounts.activateContactAccount - ", endpoint); //eslint-disable-line no-console
	return new Promise(resolve => {
		setTimeout(() => {
			resolve(true)
		}, 500);
	});
}

export const deactivateAccount = account => {

	const endpoint = `catalog/${account.isAdmin ? 'admin_users' : 'contacts'}/${account.id}/deactivate`;
	if (!DEBUG) {
		return put(endpoint);
	}

	console.info("api.accounts.deactivateAccount - ", endpoint); //eslint-disable-line no-console
	return new Promise(resolve => {
		setTimeout(() => {
			resolve(true)
		}, 500);
	});
}


export const saveAccountTeam = ({accountId, teamId, role}) => {
	const endpoint = `catalog/teams/members`;
	const params = mapper.saveAccountToTeam.toApi({ accountId, teamId, role });

	if (!DEBUG) {
		return put(endpoint, params);
	}

	console.info("api.accounts.saveAccountToTeam - ", endpoint, params); //eslint-disable-line no-console
	return new Promise(resolve => {
		setTimeout(() => {
			resolve(true)
		}, 500);
	});
}

export const saveTeamRoleAndAccess = ({ accountId, teamId, role, teamsAccess }) => {
	const endpoint = `catalog/teams/members`;
	const params = mapper.saveTeamRoleAndAccess.toApi({ accountId, teamId, role, teamsAccess });

	if (!DEBUG) {
		return put(endpoint, params);
	}

	console.info("api.companies.team.team_members - ", endpoint, params); //eslint-disable-line no-console
	return new Promise(resolve => {
		setTimeout(() => {
			resolve(true)
		}, 500);
	});
}

export const resendWelcomeEmail = ({id, isAdmin}) => {
	const endpoint = `catalog/${isAdmin ? 'admin_users' : 'contacts'}/${id}/resend_activation_instructions`;
	if (!DEBUG) {
		return post(endpoint);
	}

	console.info("api.accounts.resendWelcomeEmail - ", endpoint); //eslint-disable-line no-console
	return new Promise(resolve => {
		setTimeout(() => {
			resolve(true)
		}, 500);
	});
}


export const searchCompany = search => {
	const endpoint = `catalog/search/objects`;
	const params = mapper.searchCompany.toApi(search);
	if (!DEBUG) {
		return get(endpoint, params);
	}

	console.info("api.accounts.searchCompany - ", endpoint, params); //eslint-disable-line no-console
	return new Promise(resolve => {
		setTimeout(() => {
			resolve(Array.from({length: 10}).map(() => {
				const id = Math.floor(Math.random()*1000)
				return {
					id,
					name: `Kolsquare ${id}`
				}
			})
		)}, 500);
	});
}

export const loadCompany = ({ id }) => {
  const endpoint = `catalog/companies/${id}`;
  if (!DEBUG) return get(endpoint);

  console.info("api.accounts.loadCompany - ", endpoint); //eslint-disable-line no-console
  let timeout;
  return new Promise((resolve) => {
    if(timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      resolve(generateRandomCompany());
    }, 500);
  });
};

export const loadCampanyMonitoringRights = ({ companyId }) => {
	const endpoint = `catalog/monitoring_rights/salesforce_account/${companyId}`;
	if (!DEBUG) return get(endpoint);

	let timeout;
	return new Promise((resolve) => {
	  if(timeout) clearTimeout(timeout);
	  timeout = setTimeout(() => {
		resolve(() => ({
			monitoring_rights: []
		}));
	  }, 500);
	});
}

export const saveMonitoringRights = ({ monitoring_id, params }) => {
	const endpoint = `catalog/monitoring_rights/${monitoring_id}`;
	if (!DEBUG) return put(endpoint, params);

	let timeout;
	return new Promise((resolve) => {
	  if(timeout) clearTimeout(timeout);
	  timeout = setTimeout(() => {
		resolve(() => ({
			monitoring_rights: []
		}));
	  }, 500);
	});
}

export const loadCompanyLicences = ({ id }) => {
  const endpoint = `catalog/companies/${id}/licences`;
  if (!DEBUG) return get(endpoint);

  console.info("api.accounts.loadCompanyLicences - ", endpoint); //eslint-disable-line no-console
  let timeout;
  return new Promise((resolve) => {
	if(timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      resolve(generateRandomCompanyLicences());
    }, 500);
  });
};

export const saveCompanyLicences = ({ id, licences }) => {
	const endpoint = `catalog/companies/${id}/licences/batch`;
	const params = mapper.saveCompanyLicences.toApi(licences);
	if (!DEBUG) return post(endpoint, params);

	console.info("api.accounts.saveCompanyLicences - ", endpoint); //eslint-disable-line no-console
	let timeout;
	return new Promise((resolve) => {
	if(timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
		resolve(generateRandomCompanyLicences());
	  }, 500);
	});
};

/**
 * Enable or disable 2FA
 * @param {number} id company or user id 
 * @param {boolean} otp_required_for_login company or user id 
 * @param {'companies' | 'contacts'} type companies | contacts
 * @returns {void} void
 */
export const saveOptRequiredForLogin = ({ id, otp_required_for_login, type = 'companies' }) => {
  const endpoint = `catalog/${type}/${id}/switch_two_factor`;
  const params = { active: otp_required_for_login };
  if (!DEBUG) return put(endpoint, params);
};

/**
 * Gigapay
 * @param {number} salesforce_account_id
 */
export const sendDataToGigapay = ({ salesforce_account_id }) => {
	const endpoint = `catalog/payment_information_form/send_to_gigapay`;
	const params = { salesforce_account_id };
	if (!DEBUG) return post(endpoint, params);
};

export const getDataForGigapay = ({ salesforce_account_id }) => {
	const endpoint = `catalog/payment_information_form`;
	const params = { salesforce_account_id };
	if (!DEBUG) return get(endpoint, params);
};








export const getNsmDashboard = ({ teamId, contactId }) => {
  const params = teamId
  	? { team_id: teamId }
	: { contact_id: contactId };

  if (!DEBUG) return get(
	`catalog/lighthouse`,
	params,
	[404]
);
};

export const saveNsmDashboard = ({ teamId, contactId, mentions }) => {
  const queryStringParams = teamId
  	? `team_id=${teamId}`
	: `contact_id=${contactId}`;

  if (!DEBUG) {
	const endpoint = `catalog/lighthouse?${queryStringParams}`;
	const params = { social_network_mentions: mentions.reduce((prev, curr, index) => ({
		...prev,
		[index]: curr,
	}), {}), };

	return put(endpoint, params)
  }
};
