import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import {
  LinearProgress,
  Dialog,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  TextField,
  CircularProgress,
  FormLabel,
} from '@material-ui/core';

import { loadCampanyMonitoringRights, saveMonitoringRights } from 'api/accounts';
import { login } from 'actions/auth';
import { updateUser } from 'actions/env';
import getLexique from 'locales';
import { useDispatch, useSelector } from "utils/redux";

import styles from './DoubleAuthModal.module.scss';

const initialState = {
  code: "",
  error: "",
  loading: false,
}

const VerificationCodeModal = ({ open=false, onClose, otp_user_id, email, password }) => {

  const dispatch = useDispatch();

  const [state, setState] = useState(initialState);

  const { lexique } = useSelector(({ env: { locale } }) => ({
    lexique: getLexique(locale)?.accounts?.user?.admin2Fa,
  }));

  const handleClose = useCallback(
    () => {
      setState(initialState)
      if(onClose) onClose();
    },
    [onClose]
  );

  const handleSubmitVerificationCode = useCallback(
    async () => {
      setState(s => ({ ...s, loading: true }));
      const response = await dispatch(login({
        otp_user_id,
        otp_attempt: state.code,
        email,
        password,
      }));
      if (response?.error) {
        setState(s => ({
          ...s,
          loading: false,
          error: response?.error_code || response?.error
        }));
      } else {
        handleClose();
      }
    },
    [dispatch, handleClose, state.code, otp_user_id]
  );

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <div className="modal-content">
        <div className="modal-content-header">
          {lexique?.setup?.title}
        </div>
        <div className="modal-content-body">
          <FormLabel component="label">
            {lexique.setup.oneTimeCode.description}
          </FormLabel>
          <TextField
            id="auth-code"
            placeholder="xxxxxx"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            classes={{root: styles.inputRoot}}
            name="code"
            value={state.code}
            onChange={({ target }) => {
              setState(s => ({
                ...s,
                code: target?.value,
                error: ""
              }));
            }}
            disabled={state.loading}
            error={!!state.error}
            helperText={state.error ? lexique.setup.error[state.error] : ""}
          />
        </div>

        <div className="modal-content-footer">
          <Button
            variant="contained"
            color="default"
            onClick={handleClose}
            className="btn-raised"
            disableElevation
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitVerificationCode}
            className="btn-raised btn-raised-green"
            disableElevation
            disabled={state.loading || state.code?.length === 0}
          >
            Envoyer
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

VerificationCodeModal.displayName = 'VerificationCodeModal';

export default VerificationCodeModal;
